@import url("https://fonts.googleapis.com/earlyaccess/notosanskr.css");

html, body, #root {
  font-family:'Noto Sans CJK KR', Noto Sans KR, sans-serif !important;
  font-weight: 500;
  margin: 0;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus-visible {
  outline: unset;
}

img {
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 320px) {
  html, body, #root {
    font-size: 9px;
  }
  #booking-list-title {
    padding-top: 20px;
  }
}

@media (max-width: 280px) {
  html, body, #root {
    font-size: 8px;
  }
}

/* const width */
.w-unset { width: unset !important; }
.w-fit { width: fit-content !important; }
.w-100 { width: 100%; }
.w-60 { width: 60% !important; }
.w-50 { width: 50%; }
.w-110 { width: 110px !important; }
.w-100-40 { width: calc(100% - 40px) !important; }
.w-350 { width: 350px !important; }

/* height */
.h-0 { height: 0 !important; }
.h-25 { height: 25px; }
.h-40 { height: 40px !important; }
.h-60 { height: 60px !important; }
.lh-25 { line-height: 25px; }

/* const background */
.bc-dark { background-color: #1f1f1f; }

/* const border */
.br-2 { border-radius: 3.2rem !important; }
.bt-1-gray { border-top: 1px solid #d9d9d9; }

/* box */
.bs-unset { box-shadow: unset !important; }

/* const display */
.d-inline-block { display: inline-block; }
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }
.d-block { display: block; }

/* const position */
.p-relative { position: relative; }
.p-absolute { position: absolute !important; }
.r-0 { right: 0; }
.r-8 { right: 8px; }
.r-10 { right: 10px; }
.r-20 { right: 20px; }
.l-8 { left: 8px; }
.l-10 { left: 10px; }
.t-0 { top: 0px; }

/* const cursor */
.pointer { cursor: pointer; }

/* const text-align */
.ta-center { text-align: center; }

/* const font */
.fs-10 { font-size: 1rem; }
.fs-11 { font-size: 1.1rem; }
.fs-12 { font-size: 1.2rem; }
.fs-13 { font-size: 1.3rem !important; }
.fs-14 { font-size: 1.4rem; }
.fs-15 { font-size: 1.5rem; }
.fs-16 { font-size: 1.6rem; }
.fs-18 { font-size: 1.8rem; }
.fs-20 { font-size: 2rem; }
.bold { font-weight: bold; }
.c-lightgray { color: rgba(0, 0, 0, 0.25) !important;}
.c-gray { color: #687374; }
.c-darkgray { color: #595959; }
.c-green { color: #00af89 !important; }
.c-red { color: #f7797b; }
.c-black { color: #000000; }
.c-blue { color: #00b9f2 !important; }
.hover-blue:hover { color: #40a9ff; }
.c-sky-blue { color: #8fb0cf !important; }
.c-black { color: #333 !important; }
.ws-pl { white-space: pre-line; }
.ws-nowrap { white-space: nowrap; }
.wb-ka { word-break: keep-all; }
.underline { text-decoration: underline; }

/* overflow */
.o-hidden { overflow: hidden; }
.to-ellipsis { text-overflow: ellipsis; }

/* const padding */
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-45 { padding-top: 45px; }
.pt-70 { padding-top: 70px; }
.pb-70 { padding-bottom: 70px; }
.pl-10 { padding-left: 10px !important; }

/* const margin */
.m-auto { margin: auto; }
.m-0 { margin: 0px; }
.m-10 { margin: 10px; }
.m-10-15 { margin: 10px 15px; }
.mt-0 { margin-top: 0px; }
.mt-1 { margin-top: 1px; }
.mt-2 { margin-top: 2px; }
.mt-3 { margin-top: 3px; }
.mt-4 { margin-top: 4px; }
.mt-5 { margin-top: 5px; }
.mt-8 { margin-top: 8px; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-22 { margin-top: 22px !important; }
.mt-26 { margin-top: 26px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-35 { margin-top: 35px !important; }
.mt-45 { margin-top: 45px; }
.mt-52 { margin-top: 52px; }
.mb-0 { margin-bottom: 0 !important; }
.mb-2 { margin-bottom: 2px; }
.mb-4 { margin-bottom: 4px !important; }
.mb-5 { margin-bottom: 5px; }
.mb-8 { margin-bottom: 8px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-12 { margin-bottom: 12px; }
.mb-14 { margin-bottom: 14px; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-27 { margin-bottom: 27px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-32 { margin-bottom: 32px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mr-2 { margin-right: 2px; }
.mr-3 { margin-right: 3px; }
.mr-4 { margin-right: 4px; }
.mr-5 { margin-right: 5px; }
.mr-6 { margin-right: 6px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }
.mr-35 { margin-right: 35px; }
.mr-80 { margin-right: 80px; }
.ml-0 { margin-left: 0px !important; }
.ml-2 { margin-left: 2px; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-6 { margin-left: 6px; }
.ml-10 { margin-left: 10px; }
.ml-12 { margin-left: 12px; }
.ml-14 { margin-left: 14px; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }
.ml-35 { margin-left: 35px; }
.ml-45 { margin-left: 45px; }

/* animation */
.shake {
  transform-origin: bottom;
  animation: shake 2.5s infinite linear;
}
@keyframes shake {
  0%, 50% {
    transform: rotate(0deg);
	}
  5%, 15%, 25%, 35%, 45% {
    transform: rotate(13deg);
  }
  10%, 20%, 30%, 40% {
    transform: rotate(-13deg);
  }
}

/* button */
/* .basic-button {
  display: block !important;
  width: 100%;
  height: 50px;
  font-size: 1.8rem;
  font-weight: bold !important;
  color: #fff !important;
}
  .basic-button.w-0 {
    width: unset;
  }
  .basic-button.gray,
  .basic-button.gray:focus {
    background-color: #c6c6c6;
    border-color: #c6c6c6;
  }
    .basic-button.gray:hover {
      background-color: #d4d4d4;
      border-color: #d4d4d4;
    }
  .basic-button.green,
  .basic-button.green:focus {
    background-color: #00af89;
    border-color: #00af89;
  }
    .basic-button.green:hover,
    .basic-button.green:focus {
      background-color: #1ebd95;
      border-color: #1ebd95;
    }
  .basic-button.blue,
  .basic-button.blue:focus {
    background-color: #00b9f2;
    border-color: #00b9f2;
  }
    .basic-button.blue:hover {
      background-color: #29d4ff;
      border-color: #29d4ff;
    }
.fix-refresh-button {
  width: 50px;
  height: 50px;
  padding: 0 10px !important;
  background-color: #434343 !important;
  border-radius: 3.2rem !important;
  border-color: #434343 !important;
  font-size: 22px !important;
}
  .fix-refresh-button > span {
    line-height: 0 !important;
  } */

/* alert */
/* .alert-success .ant-message-notice-content {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.alert-fail .ant-message-notice-content {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
} */

/* modal */
/* .modal-location-image svg {
  width: 80px !important;
  height: 80px;
  color: #00b9f2;
}
.ant-modal-title {
  font-size: 1.8rem;
  text-align: center;
}
.ant-modal-footer {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
@media (max-width: 320px) {
  .ant-modal-body {
    padding: 24px 15px;
  }
} */

/* PDF */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: unset !important;
}
.react-pdf__Page__svg,
.react-pdf__Page__svg > svg,
.react-pdf__Page__textContent {
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Page {
  border-bottom: 1px dotted;
}

/* step */
/* .ant-steps-item-finish.step-wrap .ant-steps-item-content svg,
.ant-steps-item-process.step-wrap .ant-steps-item-content svg {
  color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon {
  border-color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon > .ant-steps-icon {
  color: #025070;
}
.ant-steps-item-finish.step-wrap > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #025070;
}
.ant-steps-item-process.step-wrap .ant-steps-item-icon {
  background: #025070;
  border-color: #025070;
}
.step-wrap .ant-steps-item-tail {
  margin-left: 18px !important;
  padding: 3.5px 18px !important;
}
.step-wrap .ant-steps-item-icon {
  margin-left: 6px !important;
}
.step-wrap .ant-steps-item-content {
  width: 37px !important;
  margin-top: 0 !important;
  text-align: center !important;
} */

/* tooltip */
/* .ant-tooltip {
  z-index: 0 !important;
}
  .ant-tooltip .basic-button {
    font-size: 13px !important;
  } */

/* notification */
/* .ant-notification {
  z-index: 0 !important;
} */

/* input box */
/* .basic-input {
  height: 50px;
}
  .basic-input input {
    font-size: 1.8rem;
  }
  .ant-row.ant-form-item {
    margin-bottom: 18px;
  }
    .ant-row.ant-form-item.ant-form-item-has-success {
      margin-bottom: 18px;
    }
    .ant-row.ant-form-item.ant-form-item-with-help {
      margin-bottom: 0 !important;
    }
    .ant-row.ant-form-item .ant-form-item-explain {
      min-height: 18px !important;
      font-size: 1.2rem !important
    }
    .ant-row.ant-form-item.fs-20 label {
      font-size: 2rem !important;
    }
    .ant-row.ant-form-item.c-red label {
      color: #f7797b;
    }
    .ant-row.ant-form-item.c-green label {
      color: #00af89;
    }
   */
  /* form card style */
/* .form-card-wrap {
  border: 1px solid #cdcdcd;
}
.card {
  padding: 20px 0 !important;
  border-radius: 0.672rem;
}
  .card .ant-row.ant-form-item {
    margin: 0 20px;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help .ant-form-item-explain {
    text-align: center;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex .ant-form-item-control-input-content {
    display: inline-flex;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex input {
    font-size: 14px;
  }
  .card .ant-row.ant-form-item .ant-col.ant-form-item-label label {
    font-size: 1.6rem;
    font-weight: bold;
  }
    .card .ant-row.ant-form-item .ant-col.ant-form-item-label .anticon.anticon-question-circle.ant-form-item-tooltip {
      margin-left: 2px;
      font-size: 1.6rem;
    } */

/* form item */
/* .ant-form .ant-form-item .ant-form-item-label {
  flex: unset !important;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}
.ant-form .ant-form-item .ant-form-item-control {
  flex: unset !important;
} */

/* form inline(label and input) item */
/* .inline-label-input {
  margin-top: 20px;
}
  .inline-label-input label {
    margin-top: 3px !important;
  }
  .inline-label-input .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .inline-label-input .inline-item .ant-form-item-control {
    margin-left: 20px;
    width: calc(100% - 70px);
  }
  @media (min-width: 575px) {
    .inline-label-input .inline-item .ant-form-item-control {
      margin-left: 20px;
      width: calc(100% - 80px);
    }
  }
  .inline-label-input .inline-item .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    color: #40a9ff;
  } */

/* casacader */

/* .ant-cascader-menus {
  min-height: 250px;
  z-index: 1000;
}
.ant-cascader-menus ul {
  min-height: 250px;
}
@media (min-height: 650px) {
  .ant-cascader-menus ul {
    min-height: 330px;
  }
}
@media (min-height: 760px) {
  .ant-cascader-menus ul {
    min-height: 430px;
  }
}
 */
